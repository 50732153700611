import { Queue } from './Queue'
import { toposort } from './toposort'
import { DataFragment, MaterializerFactory, Visitor, Node, Ref, RefProvider, InferSchema, Update } from './types'
import { getByArray, getByString, isObjectLike, take, setByArray, setByString, hasByString } from './utils'

export * from './types'

const BIG_FACTOR = 2048
const SMALL_FACTOR = 128

const isPlainObject = (value: unknown) => {
	if (Object.prototype.toString.call(value) !== '[object Object]') {
		return false
	}
	const prototype = Object.getPrototypeOf(value)
	return prototype === null || prototype === Object.prototype
}

const traverse = (obj: any, visit: Visitor, queueFactor: number) => {
	const queue = new Queue<Node>(queueFactor)
	queue.enqueue({ path: [], val: obj })

	while (!queue.isEmpty()) {
		const next = queue.dequeue()
		if (!visit(next.val, next.path)) {
			if (Array.isArray(next.val)) {
				for (let i = 0; i < next.val.length; i++) {
					queue.enqueue({
						path: [...next.path, i],
						val: next.val[i],
					})
				}
			} else if (isPlainObject(next.val)) {
				const keys = Object.keys(next.val)
				for (const key of keys) {
					queue.enqueue({
						path: [...next.path, key],
						val: next.val[key],
					})
				}
			}
		}
	}
}

const isRef = (x: any) => typeof x === 'object' && x !== null && x.hasOwnProperty('$type') && x.$type === 'ref'

export const inferSchema: InferSchema = (dataFragment) => {
	const schema = {}
	const removed: Array<Array<string | number>> = []
	traverse(
		dataFragment,
		(value, path) => {
			if (typeof value === 'undefined') {
				removed.push(path)
				return true
			}
			if (isRef(value)) {
				setByArray(schema, path, value)
				return true
			}
		},
		BIG_FACTOR
	)
	return { schema, removed }
}

export const createMaterializer: MaterializerFactory = ({ observedRoots, depth, transform }) => {
	const template = {}
	const materialized = {}
	const schemas = {}
	const pendingInvalidations = new Set<string>()
	const index: Record<string, Set<string>> = {}

	const mergeTemplates = (newTemplate: DataFragment) => {
		traverse(
			newTemplate,
			(value, path) => {
				if (typeof value === 'undefined') {
					const oldTemplate = getByArray(template, path)
					traverse(
						oldTemplate,
						(__, oldPath) => {
							if (path.length + oldPath.length === depth) {
								pendingInvalidations.add([...path, ...oldPath].join('.'))
								return true
							}
						},
						SMALL_FACTOR
					)
					setByArray(template, path, undefined)
					return true
				}

				if (path.length === depth) {
					const oldTemplate = getByArray(template, path)
					pendingInvalidations.add(path.join('.'))
					if (isObjectLike(oldTemplate)) {
						setByArray(template, path, { ...oldTemplate, ...value })
					} else {
						setByArray(template, path, value)
					}
					return true
				}
			},
			BIG_FACTOR
		)
	}

	const mergeSchemas = (newSchema: DataFragment, removed: Array<Array<string | number>> = []) => {
		removed.forEach((pathToRemove) => {
			const oldSchema = getByArray(schemas, pathToRemove)
			if (!oldSchema) {
				return
			}

			traverse(
				oldSchema,
				(val) => {
					if (val.hasOwnProperty('$type')) {
						const oldRefPath = take(val.refPath, depth).join('.')
						index[oldRefPath].delete(take(pathToRemove, depth).join('.'))
						return true
					}
				},
				SMALL_FACTOR
			)
		})
		traverse(
			newSchema,
			(value, path) => {
				if (!value.hasOwnProperty('$type')) {
					return
				}

				const oldSchema = getByArray(schemas, path)
				if (oldSchema) {
					const oldRefPath = take(oldSchema.refPath, depth).join('.')
					index[oldRefPath] = index[oldRefPath] || new Set<string>()
					index[oldRefPath].delete(take(path, depth).join('.'))
				}

				setByArray(schemas, path, value)
				const refPath = take(value.refPath, depth).join('.')
				index[refPath] = index[refPath] || new Set<string>()
				index[refPath].add(take(path, depth).join('.'))
				return true
			},
			BIG_FACTOR
		)
	}

	const getAllInvalidations = (invalidations: Set<string>) => {
		const allInvalidations: Set<string> = new Set<string>()
		const queue = new Queue<Set<string>>(BIG_FACTOR)
		queue.enqueue(invalidations)
		while (!queue.isEmpty()) {
			const paths = queue.dequeue()
			paths.forEach((p) => {
				if (allInvalidations.has(p)) {
					return
				}

				allInvalidations.add(p)
				if (index[p]) {
					queue.enqueue(index[p])
				}
			})
		}
		return allInvalidations
	}

	const populate = (invalidations: Set<string>) => {
		const allInvalidations: Set<string> = getAllInvalidations(invalidations)
		const paths = toposort(allInvalidations, index)

		paths.forEach((path) => {
			const val = getByString(template, path)

			if (!hasByString(schemas, path)) {
				setByString(materialized, path, transform ? transform(val, path.split('.')) : val)
				return
			}
			const nodeSchema = getByString(schemas, path)
			let newVal = {}
			traverse(
				val,
				(objValue, objPath) => {
					const schema = getByArray(nodeSchema, objPath)
					if (!schema) {
						setByArray(newVal, objPath, objValue)
						return true
					}
					if (schema.hasOwnProperty('$type')) {
						const resolved = getByArray(materialized, schema.refPath)
						if (objPath.length > 0) {
							setByArray(newVal, objPath, resolved)
						} else {
							newVal = resolved
						}
						return true
					}
					if (Array.isArray(objValue)) {
						setByArray(newVal, objPath, [])
						return
					}
				},
				SMALL_FACTOR
			)
			setByString(materialized, path, transform ? transform(newVal, path.split('.')) : newVal)
		})

		return paths
	}

	const flush = () => {
		const recursiveInvalidations = populate(pendingInvalidations)

		pendingInvalidations.clear()

		return recursiveInvalidations.map((x) => x.split('.')).filter(([root]) => observedRoots.includes(root))
	}

	const updateWithoutFlush: Update<void> = (fragment, { schema, removed } = inferSchema(fragment)) => {
		mergeSchemas(schema, removed)

		mergeTemplates(fragment)
	}

	return {
		update(fragment, schema) {
			updateWithoutFlush(fragment, schema)
			return flush()
		},
		batch(batchFunction) {
			batchFunction(updateWithoutFlush)
			return flush()
		},
		get: (path) => (Array.isArray(path) ? getByArray(materialized, path) : getByString(materialized, path)),
	}
}

export const createRef = (refPath: Array<string>): Ref => ({ $type: 'ref', refPath })

export const getBoundRefProvider = (pathToBind: Array<string>): RefProvider => (innerPath: Array<string>) =>
	createRef([...pathToBind, ...innerPath])
